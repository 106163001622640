import React from 'react';
import LocallySourcedImage from '../../images/locally-sourced.png';
import DonutsCoffeeVibes from '../atoms/DonutsCoffeeVibes';

interface MoreThanDonutShopSectionProps {
  heading: string;
  cards: Array<{
    image: {
      altText: string;
      localFile: {
        url: string;
      };
    };
    title: string;
    body: string;
  }>;
}

const MoreThanDonutShopSection = ({
  heading,
  cards,
}: MoreThanDonutShopSectionProps) => {
  return (
    <section className="pt-16 pb-6 overflow-hidden bg-linen md:pt-24 text-navy-blue">
      <div className="container">
        <h2 className="mb-16 text-center u-h2">{heading}</h2>
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
          {cards.map((card, index) => (
            <div
              key={card.title}
              className="flex flex-col items-center p-12 bg-white rounded-[10px] text-center relative"
            >
              <img
                src={card.image.localFile.url}
                alt={card.image.altText}
                className="w-auto h-40 mb-8"
              />
              <h3 className="mb-4 u-h3">{card.title}</h3>
              <p className="u-p">{card.body}</p>
              {index === 1 && (
                <img
                  src={LocallySourcedImage}
                  alt="Locally Sourced"
                  className="absolute z-10 block h-auto w-36"
                  style={{
                    top: 'calc(100% - 72px)',
                    left: 'calc(100% - 72px)',
                  }}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <DonutsCoffeeVibes className="mx-auto w-auto h-16 sm:h-20 md:h-32 lg:h-[151px]" />
    </section>
  );
};

export default MoreThanDonutShopSection;
