import React from 'react';
import SimpleHeroSection from '../molecules/SimpleHeroSection';

interface AboutHeroSectionProps {
  heading: string;
  body: string;
}

const AboutHeroSection = ({ heading, body }: AboutHeroSectionProps) => (
  <SimpleHeroSection>
    <div className="container text-center text-navy-blue">
      <div className="max-w-2xl mx-auto">
        <h1 className="mb-4 u-h2">{heading}</h1>
        <div
          className="prose prose-md"
          dangerouslySetInnerHTML={{ __html: body }}
        />
      </div>
    </div>
  </SimpleHeroSection>
);

export default AboutHeroSection;
