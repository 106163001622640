import React from 'react';
import Button from '../atoms/Button';

interface WorkWithUsSectionProps {
  icon: {
    altText: string;
    localFile: {
      url: string;
    };
  };
  heading: string;
  body: string;
  buttonLabel: string;
  buttonUrl: string;
}

const WorkWithUsSection = ({
  icon,
  heading,
  body,
  buttonLabel,
  buttonUrl,
}: WorkWithUsSectionProps) => {
  return (
    <section className="py-16 bg-linen lg:py-24">
      <div className="max-w-2xl px-5 md:px-8 mx-auto text-center text-navy-blue">
        <img
          src={icon.localFile.url}
          alt={icon.altText}
          className="w-auto h-40 mx-auto mb-10"
        />
        <h2 className="mb-4 u-h2">{heading}</h2>
        <div className="mb-8 u-p" dangerouslySetInnerHTML={{ __html: body }} />
        <Button to={buttonUrl}>{buttonLabel}</Button>
      </div>
    </section>
  );
};

export default WorkWithUsSection;
