import { motion, useTransform, useViewportScroll } from 'framer-motion';
import { getImage } from 'gatsby-plugin-image';
import React from 'react';
import BeansLeft from '../atoms/BeansLeft';
import BeansRight from '../atoms/BeansRight';
import Image from '../atoms/Image';

interface TheStorySectionProps {
  heading: string;
  body: string;
  images: Array<{
    altText: string;
    localFile: any;
  }>;
}

const TheStorySection = ({ heading, body, images }: TheStorySectionProps) => {
  const { scrollY } = useViewportScroll();
  const y1 = useTransform(scrollY, [0, 2000], [0, -250], { clamp: false });
  const y2 = useTransform(scrollY, [0, 2000], [100, -450], { clamp: false });
  const y3 = useTransform(scrollY, [0, 2000], [100, -150], { clamp: false });

  return (
    <div className="relative pt-56 pb-72 sm:py-16 sm:overflow-hidden md:py-24">
      <BeansLeft
        className="absolute left-0 -bottom-32 -z-1"
        style={{ y: y1 }}
      />
      <BeansRight className="absolute right-0 -top-32 -z-1" style={{ y: y3 }} />

      <div className="container text-center text-navy-blue">
        <div className="relative max-w-lg mx-auto md:max-w-xl lg:max-w-2xl">
          <h1 className="mb-4 u-h2">{heading}</h1>
          <div
            className="prose prose-md"
            dangerouslySetInnerHTML={{ __html: body }}
          />
          <motion.div
            className="absolute -ml-6 -top-64 sm:top-0 left-1/2 sm:ml-0 sm:-left-80 md:-left-96 sm:-z-1"
            style={{ y: y1 }}
          >
            <Image
              image={getImage(images[0].localFile)}
              alt={images[0].altText}
              className="w-[184px] sm:w-[300px] h-auto transform rotate-[10deg] "
            />
          </motion.div>
          <motion.div
            className="absolute -ml-8 -top-64 sm:ml-0 sm:top-auto sm:bottom-0 left-20 sm:-left-80 md:-left-96 sm:-z-1"
            style={{ y: y2 }}
          >
            <Image
              image={getImage(images[1].localFile)}
              alt={images[1].altText}
              className="w-[203px] sm:w-[330px] h-auto transform -rotate-[11deg] "
            />
          </motion.div>
          <motion.div
            className="absolute ml-2 -bottom-96 sm:bottom-0 md:ml-20 left-1/2 sm:left-full sm:-z-1"
            style={{ y: y1 }}
          >
            <Image
              image={getImage(images[3].localFile)}
              alt={images[3].altText}
              className="w-[221px] sm:w-[304px] h-auto transform rotate-[11deg] -ml-16 sm:ml-0 relative top-32 sm:top-0"
            />
          </motion.div>
          <motion.div
            className="absolute -ml-24 sm:ml-2 -bottom-96 sm:bottom-auto sm:top-0 md:ml-24 left-1/2 sm:left-full sm:-z-1"
            style={{ y: y2 }}
          >
            <Image
              image={getImage(images[2].localFile)}
              alt={images[2].altText}
              className="w-[221px] sm:w-[304px] h-auto transform -rotate-[11deg] relative top-32 sm:top-0"
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default TheStorySection;
