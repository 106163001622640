import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/base/Layout';
import Seo from '../components/base/Seo';
import AboutHeroSection from '../components/organisms/AboutHeroSection';
import AboutLogosSection from '../components/organisms/AboutLogosSection';
import MoreThanDonutShopSection from '../components/organisms/MoreThanDonutShopSection';
import NewsletterSection from '../components/organisms/NewsletterSection';
import { PromoPopup } from '../components/organisms/PromoPopup';
import TheStorySection from '../components/organisms/TheStorySection';
import WorkWithUsSection from '../components/organisms/WorkWithUsSection';

const About = ({
  data: {
    page,
    wp: {
      popupSettings: { popupSettings },
    },
  },
}) => {
  return (
    <>
      <Layout>
        <Seo post={page} />

        <AboutHeroSection {...page.about.aboutHero} />
        <TheStorySection {...page.about.ourStorySection} />
        <MoreThanDonutShopSection {...page.about.moreThanDonutShopSection} />
        <AboutLogosSection {...page.about.logosSection} />
        <WorkWithUsSection {...page.about.workWithUsSection} />

        <NewsletterSection isLinen />
      </Layout>
      <PromoPopup {...popupSettings} />
    </>
  );
};

export const pageQuery = graphql`
  query AboutPageQuery($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      ...SEO
      about {
        aboutHero {
          heading
          body
        }
        ourStorySection {
          heading
          body
          images {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1440, placeholder: BLURRED)
              }
            }
          }
        }
        moreThanDonutShopSection {
          heading
          cards {
            image {
              altText
              localFile {
                url
              }
            }
            title
            body
          }
        }
        logosSection {
          heading
          logos {
            image {
              altText
              localFile {
                url
              }
            }
          }
          ctaHeading
          buttonLabel
          buttonUrl
        }
        workWithUsSection {
          icon {
            altText
            localFile {
              url
            }
          }
          heading
          body
          buttonLabel
          buttonUrl
        }
      }
    }
    wp {
      popupSettings {
        popupSettings {
          popupEnabled
          image {
            altText
            localFile {
              url
              childImageSharp {
                gatsbyImageData(width: 1024, placeholder: BLURRED)
              }
            }
          }
          url
        }
      }
    }
  }
`;

export default About;
